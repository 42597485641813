var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/*! ========================================================================
 * Bootstrap Toggle: bootstrap-toggle.js v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */
+function ($) {
  'use strict'; // TOGGLE PUBLIC CLASS DEFINITION
  // ==============================

  var Toggle = function (element, options) {
    (this || _global).$element = $(element);
    (this || _global).options = $.extend({}, this.defaults(), options);
    this.render();
  };

  Toggle.VERSION = "2.2.0";
  Toggle.DEFAULTS = {
    on: "On",
    off: "Off",
    onstyle: "primary",
    offstyle: "default",
    size: "normal",
    style: "",
    width: null,
    height: null
  };

  Toggle.prototype.defaults = function () {
    return {
      on: (this || _global).$element.attr("data-on") || Toggle.DEFAULTS.on,
      off: (this || _global).$element.attr("data-off") || Toggle.DEFAULTS.off,
      onstyle: (this || _global).$element.attr("data-onstyle") || Toggle.DEFAULTS.onstyle,
      offstyle: (this || _global).$element.attr("data-offstyle") || Toggle.DEFAULTS.offstyle,
      size: (this || _global).$element.attr("data-size") || Toggle.DEFAULTS.size,
      style: (this || _global).$element.attr("data-style") || Toggle.DEFAULTS.style,
      width: (this || _global).$element.attr("data-width") || Toggle.DEFAULTS.width,
      height: (this || _global).$element.attr("data-height") || Toggle.DEFAULTS.height
    };
  };

  Toggle.prototype.render = function () {
    (this || _global)._onstyle = "btn-" + (this || _global).options.onstyle;
    (this || _global)._offstyle = "btn-" + (this || _global).options.offstyle;
    var size = (this || _global).options.size === "large" ? "btn-lg" : (this || _global).options.size === "small" ? "btn-sm" : (this || _global).options.size === "mini" ? "btn-xs" : "";
    var $toggleOn = $("<label class=\"btn\">").html((this || _global).options.on).addClass((this || _global)._onstyle + " " + size);
    var $toggleOff = $("<label class=\"btn\">").html((this || _global).options.off).addClass((this || _global)._offstyle + " " + size + " active");
    var $toggleHandle = $("<span class=\"toggle-handle btn btn-default\">").addClass(size);
    var $toggleGroup = $("<div class=\"toggle-group\">").append($toggleOn, $toggleOff, $toggleHandle);
    var $toggle = $("<div class=\"toggle btn\" data-toggle=\"toggle\">").addClass((this || _global).$element.prop("checked") ? (this || _global)._onstyle : (this || _global)._offstyle + " off").addClass(size).addClass((this || _global).options.style);

    (this || _global).$element.wrap($toggle);

    $.extend(this || _global, {
      $toggle: (this || _global).$element.parent(),
      $toggleOn: $toggleOn,
      $toggleOff: $toggleOff,
      $toggleGroup: $toggleGroup
    });

    (this || _global).$toggle.append($toggleGroup);

    var width = (this || _global).options.width || Math.max($toggleOn.outerWidth(), $toggleOff.outerWidth()) + $toggleHandle.outerWidth() / 2;
    var height = (this || _global).options.height || Math.max($toggleOn.outerHeight(), $toggleOff.outerHeight());
    $toggleOn.addClass("toggle-on");
    $toggleOff.addClass("toggle-off");

    (this || _global).$toggle.css({
      width: width,
      height: height
    });

    if ((this || _global).options.height) {
      $toggleOn.css("line-height", $toggleOn.height() + "px");
      $toggleOff.css("line-height", $toggleOff.height() + "px");
    }

    this.update(true);
    this.trigger(true);
  };

  Toggle.prototype.toggle = function () {
    if ((this || _global).$element.prop("checked")) this.off();else this.on();
  };

  Toggle.prototype.on = function (silent) {
    if ((this || _global).$element.prop("disabled")) return false;

    (this || _global).$toggle.removeClass((this || _global)._offstyle + " off").addClass((this || _global)._onstyle);

    (this || _global).$element.prop("checked", true);

    if (!silent) this.trigger();
  };

  Toggle.prototype.off = function (silent) {
    if ((this || _global).$element.prop("disabled")) return false;

    (this || _global).$toggle.removeClass((this || _global)._onstyle).addClass((this || _global)._offstyle + " off");

    (this || _global).$element.prop("checked", false);

    if (!silent) this.trigger();
  };

  Toggle.prototype.enable = function () {
    (this || _global).$toggle.removeAttr("disabled");

    (this || _global).$element.prop("disabled", false);
  };

  Toggle.prototype.disable = function () {
    (this || _global).$toggle.attr("disabled", "disabled");

    (this || _global).$element.prop("disabled", true);
  };

  Toggle.prototype.update = function (silent) {
    if ((this || _global).$element.prop("disabled")) this.disable();else this.enable();
    if ((this || _global).$element.prop("checked")) this.on(silent);else this.off(silent);
  };

  Toggle.prototype.trigger = function (silent) {
    (this || _global).$element.off("change.bs.toggle");

    if (!silent) (this || _global).$element.change();

    (this || _global).$element.on("change.bs.toggle", $.proxy(function () {
      this.update();
    }, this || _global));
  };

  Toggle.prototype.destroy = function () {
    (this || _global).$element.off("change.bs.toggle");

    (this || _global).$toggleGroup.remove();

    (this || _global).$element.removeData("bs.toggle");

    (this || _global).$element.unwrap();
  }; // TOGGLE PLUGIN DEFINITION
  // ========================


  function Plugin(option) {
    return this.each(function () {
      var $this = $(this || _global);
      var data = $this.data("bs.toggle");
      var options = typeof option == "object" && option;
      if (!data) $this.data("bs.toggle", data = new Toggle(this || _global, options));
      if (typeof option == "string" && data[option]) data[option]();
    });
  }

  var old = $.fn.bootstrapToggle;
  $.fn.bootstrapToggle = Plugin;
  $.fn.bootstrapToggle.Constructor = Toggle; // TOGGLE NO CONFLICT
  // ==================

  $.fn.toggle.noConflict = function () {
    $.fn.bootstrapToggle = old;
    return this || _global;
  }; // TOGGLE DATA-API
  // ===============


  $(function () {
    $("input[type=checkbox][data-toggle^=toggle]").bootstrapToggle();
  });
  $(document).on("click.bs.toggle", "div[data-toggle^=toggle]", function (e) {
    var $checkbox = $(this || _global).find("input[type=checkbox]");
    $checkbox.bootstrapToggle("toggle");
    e.preventDefault();
  });
}(jQuery);
export default {};